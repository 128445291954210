<template>
  <div class="agreement flex">
    <div class="catalogue" :style="`height:${catalogueHeight}px`">
      <div class="items">
        <div
          v-for="item in $t('about.agreement.catalogue')"
          :key="item.id"
          @click="goTo(item.id)"
          :class="{ active: item.id === currentId }"
          class="item"
        >
          <span>
            {{ item.title }}
          </span>
        </div>
      </div>
    </div>
    <div class="content-detail flex1" :style="`height:${catalogueHeight}px`">
      <div class="about-title listItemTitle">
        {{ $t("about.agreement.title") }}
        <span class="update_date">更新日期：2022-05-16</span>
      </div>
      <div class="about-content" v-html="$t('about.agreement.content')"></div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      catalogueHeight: 700,
      currentId: "01",
    };
  },
  mounted() {
    this.handleResize();
    this.$nextTick(() => {
      window.addEventListener("resize", this.handleResize);
    });
  },
  methods: {
    handleResize() {
      this.catalogueHeight = window.innerHeight - 115;
      // this.catalogueHeight = document.querySelector(".catalogue").offsetHeight;
    },
    goTo(id) {
      this.currentId = id;
      let total = document.getElementById(id).offsetTop - 120;
      //下面是不同浏览器兼容
      document.querySelector(".content-detail").scrollTop = total;
      document.body.scrollTop = 0;
      document.documentElement.scrollTop = 0;
      window.pageYOffset = 0;
    },
  },
};
</script>
<style lang="scss" scoped>
.catalogue {
  width: 200px;
  overflow-y: auto;
  .catalogue_title {
    font-size: 16px;
    padding-left: 5px;
  }
  .items {
    padding-top: 30px;
    // position: fixed;
    // top: 80px;
    // overflow-y: auto;
    border-right: 2px solid var(--borderColor);
    position: relative;
    z-index: 300;
  }
  .item {
    cursor: pointer;
    min-height: 35px;
    font-size: 14px;
    color: var(--blackColor);
    position: relative;
    padding: 10px 0;
    span {
      display: block;
      padding-right: 25px;
      word-break: break-all;
      text-align: justify;
    }
    &.active {
      color: var(--blue);
      &::after {
        right: -2px;
        top: 0px;
        width: 3px;
        height: 100%;
        background-color: var(--blue);
        content: "";
        position: absolute;
      }
    }
  }
}
.content-detail {
  padding-left: 25px;
  color: var(--blackColor);
  overflow-y: auto;
}
.about-title {
  position: relative;
  .update_date {
    position: absolute;
    right: 0;
    bottom: 10px;
    font-weight: 400;
    font-size: 14px;
    color: var(--iconGray);
  }
}
</style>
